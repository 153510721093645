import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import List from 'components/list';
import Head from '../components/head';
import {
  Container,
  TextLeft,
  TextRight,
  ListContainer,
} from '../../content/about/about.css';

const About = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <Container>
      <TextLeft>
        <Fade bottom duration={1000} delay={600} distance="50px">
          <p>{data.aboutJson.avaibleText}</p>
          <p>{data.aboutJson.textStudy}</p>
          <p>{data.aboutJson.textExperiences1}</p>
          <p>{data.aboutJson.textExperiences2}</p>
          <p>{data.aboutJson.textExperiences3}</p>
        </Fade>
      </TextLeft>
      <TextRight>
        <Fade bottom duration={1000} delay={600} distance="50px">
          <ListContainer id="curriculum">
            <h3>Curriculum</h3>
            <hr />
            <List items={data.aboutJson.curriculum} />
          </ListContainer>
          <ListContainer id="degree">
            <h3>Degree</h3>
            <hr />
            <List items={data.aboutJson.degree} />
          </ListContainer>
          <ListContainer id="certifications">
            <h3>Certifications</h3>
            <hr />
            <List items={data.aboutJson.certifications} />
          </ListContainer>
          <ListContainer id="contact">
            <h3>Contact</h3>
            <hr />
            <List items={data.aboutJson.contact} />
          </ListContainer>
        </Fade>
      </TextRight>
    </Container>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      avaibleText
      textStudy
      textExperiences1
      textExperiences2
      textExperiences3
      curriculum {
        textColLeft
        textColMiddle
        textColRight
      }
      degree {
        textColLeft
        textColMiddle
        textColRight
      }
      certifications {
        textColLeft
        textColMiddle
        textColRight
      }
      contact {
        textColLeft
        textColMiddle
        textColRight
      }
    }
  }
`;
