import React from 'react';
import PropTypes from 'prop-types';
import Item from './item/item';

const List = ({ items }) => (
  <div>
    {items.map((item, i) => (
      <Item {...item} key={i} />
    ))}
  </div>
);

List.propTypes = {
  titleList: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default List;
