import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  padding: 5rem 10rem;
  font-weight: 300;
  ${MEDIA.PHONE`
    padding: 5rem 3rem;
  `};
`;

export const TextLeft = styled.div`
  font-size: 3.2rem;
  line-height: 3.8rem;
  display: inline-block;
  width: 40%;
  margin-bottom: 5rem;
  ${MEDIA.TABLET`
    display: block;
    width: auto;
    font-size: 2.4rem;
  `};
  ${MEDIA.PHONE`
    display: block;
    width: auto;
    font-size: 2.2rem;
    line-height: 2.8rem;
  `};
`;

export const TextRight = styled.div`
  display: inline-block;
  width: 50%;
  margin-left: 10%;
  margin-bottom: 5rem;
  vertical-align: top;
  ${MEDIA.TABLET`
    margin-left: 0;
    display: block;
    width: 100%;
  `};
  ${MEDIA.PHONE`
    margin-left: 0;
    display: block;
    width: 100%;
  `};

  #curriculum {
    .tableList {
      margin-bottom: 0.4rem;
    }
  }

  #degree {
    .tableList {
      margin-bottom: 1.8rem;
    }
  }

  #certifications {
    .tableList {
      margin-bottom: 1.8rem;
    }
  }
`;

export const ListContainer = styled.div`
  margin-bottom: 5rem;

  h3 {
    font-size: 3.4rem;
    margin-bottom: 3rem;
    ${MEDIA.PHONE`
      font-size: 2.8rem;
      margin-bottom: 2rem;
    `};
  }

  hr {
    color: #f3f3f3;
    margin-bottom: 3rem;
  }
`;
