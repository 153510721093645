import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const TableList = styled.div`
  vertical-align: top;
  display: block;
  width: 100%;
  font-size: 1.8rem;
  ${MEDIA.PHONE`
    font-size: 1.2rem;
  `};

  span {
    display: inline-block;
    vertical-align: middle;
    width: 33.33%;
    margin: 0.3rem 0;
  }

  .colLeft {
    text-align: left;
  }

  .colMiddle {
    text-align: left;
  }

  .colRight {
    text-align: right;
  }
`;
