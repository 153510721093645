import React from 'react';
import PropTypes from 'prop-types';
import { TableList } from './item.css';

const Item = ({ textColLeft, textColMiddle, textColRight }) => (
  <div>
    <TableList className="tableList">
      <span className="colLeft">{textColLeft}</span>
      <span className="colMiddle">{textColMiddle}</span>
      <span className="colRight">{textColRight}</span>
    </TableList>
  </div>
);

Item.propTypes = {
  textColLeft: PropTypes.string,
  textColMiddle: PropTypes.string,
  textColRight: PropTypes.string,
};

export default Item;
